export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const lendicaDefaultCredentials = {
  company_name: process.env.REACT_APP_LENDICA_COMPANY_NAME,
  partner_company_id: process.env.REACT_APP_LENDICA_PARTNER_COMPANY_ID,
  partner_company_uuid: process.env.REACT_APP_LENDICA_PARTNER_COMPANY_UUID,
  partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
  partner_token: process.env.REACT_APP_LENDICA_COMPANY_ACCESS_TOKEN,
};

export const lendicaDefaultConfig = {
  access_type: process.env.REACT_APP_LENDICA_ACCESS_TYPE,
  env: process.env.REACT_APP_LENDICA_ENVIRONMENT,
  fullscreen: false,
};
