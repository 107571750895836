import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Box,
} from "@mui/material";
import { spacing, display } from "@mui/system";
// import { PayLaterSvg } from "../icons/PayLaterSvg";
// import "@lendica/paylaterbutton";
import { postBill } from "../../api";

// import { useLendicaContext } from "../../hooks/useLendicaContext";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

// const stashData = {
//   total: 790,
//   partner_name: "stash_ok",
//   partner_invoice_uuid: "157234A8-8CDB-4FE4-9BF3-117956CCB7E3",
// };

function InvoiceDetails() {
  // const { lendica } = useLendicaContext();

  // useEffect(() => {
  //   if (lendica.current) {
  //     lendica.current.button.destroy();
  //     lendica.current.paylater
  //       .renderButton({
  //         containerElement: document.getElementById("paylater-container"),
  //         showAsLowAs: true,
  //         children: "Pay this invoice later",
  //       })
  //       .then(() => {
  //         lendica.current.paylater({
  //           total: 5000.0,
  //           partner_name: "demo",
  //           partner_invoice_uuid: "7",
  //         });
  //       });
  //   }
  // }, [lendica]);

  const handlePayLater = async (e) => {
    e.preventDefault();
    const redirectUrl = await postBill({
      user_type: "admin",
      integration_type: "checkout",
      pay_partner_platform: true,
      return_url: "https://example.com",
      bill: {
        partner_invoice_uuid: "checkout01",
        last_updated_datetime: "2023-02-07T18:56:06.353Z",
        order_date: "2024-01-01",
        due_date: "2024-01-01",
        invoice_number: "string",
        status: "string",
        payment_terms: "Net 7",
        subtotal: 5000,
        tax: 0,
        shipping_total: 0,
        total: 5000,
        settled: false,
        child_bills: [
          {
            partner_invoice_uuid: "child_1_uuid",
            last_updated_datetime: "2023-02-07T18:56:06.353Z",
            order_date: "1970-01-01",
            due_date: "1970-01-01",
            invoice_number: "child_1_invoice",
            status: "pending",
            payment_terms: "Net 7",
            subtotal: 5,
            tax: 0,
            shipping_total: 0,
            total: 5,
            settled: false,
          },
          {
            partner_invoice_uuid: "child_2_uuid",
            last_updated_datetime: "2023-02-07T18:56:06.353Z",
            order_date: "1970-01-01",
            due_date: "1970-01-01",
            invoice_number: "child_2_invoice",
            status: "completed",
            payment_terms: "Net 7",
            subtotal: 5,
            tax: 0,
            shipping_total: 0,
            total: 5,
            settled: true,
          },
        ],
        line_items: [
          {
            product_id: "09180gfd0",
            product_name: "Drum",
            quantity: 100,
            unit_price: 100,
            unit_of_measure: "gallons",
            line_total: 10000,
          },
        ],
        shipping_details: {
          shipping_first_name: "John",
          shipping_last_name: "Doe",
          shipping_address1: "123 Main St",
          shipping_address2: "Apt 1",
          shipping_province: "Anytown",
          shipping_country: "USA",
          shipping_zip: 12345,
          shipping_phone: 1234567890,
          shipping_email: "johndoe@example.com",
          ship_to_address: "123 Main St, Anytown, USA 12345",
        },
        company: {
          partner_company_uuid: "8226baf1-a229-4fb1-b004-850cb7560afe",
          company_name: "Checkout Corp",
          first_name: "Jobin",
          last_name: "Test",
          email: "keith+checkout111111@golendica.com",
          ownership_percent: 100,
          date_of_incorporation: "2023-02-07",
          monthly_revenue: 33333,
          website: "https://www.jobincorp.com",
          company_address: "123 Main Street, White Plains, NY, USA",
          ssn: 123456789,
          personal_credit_score: 800,
          date_of_birth: "1990-01-01",
          additional_owners: 0,
          home_address: "123 Main Street, White Plains, NY, USA",
          bank_name: "chase",
          bank_account: "1234567",
          bank_routing: "123456789",
          business_ein: 123456789,
        },
        vendor: {
          partner_company_uuid: "be3a50b9-38d3-41a3-8716-3b3c0dd0d3f1",
          company_name: "Checkout Vendor Test",
          first_name: "John",
          last_name: "Doe",
          phone_number: 7288887756,
          email: "keith+checkoutvendor@golendica.com",
          company_address: "123 Main Street, White Plains, NY, USAt",
          bank_name: "chase",
          bank_account: "1234567",
          bank_routing: "123456789",
          business_ein: 123456789,
        },
      },
    });
    if (!redirectUrl) {
      return;
    }
    return window.open(redirectUrl, "_blank");
  };

  const nextweek = () => {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    return nextweek;
  };

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Typography variant="h3" gutterBottom display="inline">
        Invoice #000112
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Link component={NavLink} to="/">
          Invoices
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      Hello Acme Co.,
                      <br />
                      This is the receipt for a payment of $5000.00 (USD) you
                      made to Demo Vendor LLC.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Payment No.</Typography>
                    <Typography variant="body2">741037024</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment Date
                    </Typography>
                    <Typography variant="body2" align="right">
                      {nextweek().toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Client</Typography>
                    <Typography variant="body2">
                      Acme Co.
                      <br />
                      4183 Forest Avenue
                      <br />
                      New York City
                      <br />
                      10011
                      <br />
                      USA
                      <br />
                      <Link href="mailto:ap@acme.co">ap@acme.co</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment To
                    </Typography>
                    <Typography variant="body2" align="right">
                      Demo Vendor LLC
                      <br />
                      354 Roy Alley
                      <br />
                      Denver
                      <br />
                      80202
                      <br />
                      USA
                      <br />
                      <Link href="mailto:ar@demo.com">ar@demo.com</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Line Item 1
                    </TableCell>
                    <TableCell>2</TableCell>
                    <TableCell align="right">$1,500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Line Item 2
                    </TableCell>
                    <TableCell>3</TableCell>
                    <TableCell align="right">$2,500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Line Item 3
                    </TableCell>
                    <TableCell>2</TableCell>
                    <TableCell align="right">$1,000.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right">$5,000.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Shipping</TableCell>
                    <TableCell align="right">$0.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Discount</TableCell>
                    <TableCell align="right">0%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Total</TableCell>
                    <TableCell align="right">$5,000.00</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                <div>
                  <Typography
                    variant="caption"
                    paragraph
                    gutterBottom
                    align="center"
                  >
                    Extra note: Please send all items at the same time to the
                    shipping address. Thanks in advance.
                  </Typography>
                </div>
                {/* <Link
                  sx={{ mt: 2 }}
                  href="https://sandbox.golendica.com/api/v1/pod/paylater/partner_paylater_redirect/?partner=apexdemo&invoice_id=fc5204ce-f758-4372-901a-966feec0bf73"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PayLaterSvg height={36} />
                </Link> */}
                {/* <paylater-button
                  onClick={() =>
                    window.open(
                      "https://paylater.app-idev.golendica.com/?partner_name=apexdemo&partner_invoice_uuid=db9260e5-56e7-44a0-8e90-a49b25e983a6",
                      "_blank"
                    )
                  }
                ></paylater-button> */}
                {/* <Box
                  id="paylater-container"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >      
                </Box> */}
                <Box
                  sx={{
                    width: "100%",
                    height: 40,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handlePayLater}
                    sx={{
                      backgroundColor: "#202024",
                    }}
                  >
                    Pay this invoice later
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InvoiceDetails;
