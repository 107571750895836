import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
// import { green, orange, red, grey } from "@mui/material/colors";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useLendicaContext } from "../../hooks/useLendicaContext";

import { PAYMENT_TERMS } from "../../constants";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

// const Chip = styled(MuiChip)`
//   ${spacing};

//   background: ${(props) => props.default && green[500]};
//   background: ${(props) => props.processing && orange[700]};
//   background: ${(props) => props.complete && grey[500]};
//   background: ${(props) => props.cancelled && red[500]};
//   color: ${(props) => props.theme.palette.common.white};
// `;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function createData(id, displayId, customer, date, total, status, method) {
  return { id, displayId, customer, date, total, status, method };
}

const invoiceDefault = [
  {
    partner_invoice_uuid: "1",
    invoice_number: "#SDF-0011",
    date: "2023-06-15",
    total: 6820,
    due_date: "2023-06-20",
    customer: "TechMach Industries",
  },
  {
    partner_invoice_uuid: "2",
    invoice_number: "#SDF-0012",
    date: "2023-06-12",
    total: 2380,
    due_date: "2023-07-15",
    customer: "Garden Treasures",
  },
  {
    partner_invoice_uuid: "3",
    invoice_number: "#SDF-0013",
    date: "2023-05-28",
    total: 4300,
    due_date: "2023-06-15",
    customer: "The Fashion Loft",
  },
];

const getRows = (invoiceArr = invoiceDefault) =>
  invoiceArr.map((invoice) =>
    createData(
      invoice?.partner_invoice_uuid,
      invoice?.invoice_number,
      invoice?.customer,
      `${invoice?.date}`.slice(0, 10),
      `$${invoice?.total.toFixed(2)}`,
      invoice?.due_date,
      invoice?.method || PAYMENT_TERMS.NET30
    )
  );

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "id", alignment: "right", label: "Order ID" },
  { id: "customer", alignment: "left", label: "Customer" },
  { id: "date", alignment: "left", label: "Date" },
  { id: "total", alignment: "right", label: "Total" },
  { id: "status", alignment: "center", label: "Status" },
  { id: "method", alignment: "left", label: "Payment Method" },
  { id: "actions", alignment: "right", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Orders
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { isConfigured, getSalesOrders } = useLendicaContext();

  const [rows, setRows] = React.useState(getRows(invoiceDefault));

  React.useEffect(() => {
    (async () => {
      if (!isConfigured) {
        return;
      }
      try {
        const invoiceData = await getSalesOrders();
        setRows(getRows(invoiceData));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [isConfigured, getSalesOrders]);

  // const handleStatusClick = (e, orderId, status) => {
  //   console.log(orderId, status);
  //   if (lendica?.current && typeof lendica?.current?.open === "function") {
  //     lendica?.current?.open(status === 99 ? null : orderId);
  //   }
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>

                      <TableCell align="right">{row.displayId}</TableCell>
                      <TableCell align="left">{row.customer}</TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                      <TableCell>
                        <fundnow-trigger
                          invoice-id={row.id}
                          total={row.total}
                        ></fundnow-trigger>
                        {/* <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label="Get Paid Now"
                          default
                          onClick={() => {
                            lendica?.current.ibranch.openFundNow(row.id);
                          }}
                        /> */}
                        {/* {row.status === 99 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Get Paid Now"
                            default={+true}
                            onClick={(e) => {
                              handleStatusClick(e, row.id, row.status);
                            }}
                          />
                        )}
                        {row.status === 0 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Get Paid Now"
                            default={+true}
                            onClick={(e) => {
                              handleStatusClick(e, row.id, row.status);
                            }}
                          />
                        )}
                        {row.status === 1 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Send Reminder"
                            processing={+true}
                            onClick={(e) => {
                              handleStatusClick(e, row.id, row.status);
                            }}
                          />
                        )}
                        {row.status === 2 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Confirm Delivery"
                            processing={+true}
                            onClick={(e) => {
                              handleStatusClick(e, row.id, row.status);
                            }}
                          />
                        )}
                        {row.status === 3 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="See Progress"
                            processing={+true}
                            onClick={(e) => {
                              handleStatusClick(e, row.id, row.status);
                            }}
                          />
                        )}
                        {row.status === 4 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Paid Off"
                            complete={+true}
                            onClick={(e) => {
                              handleStatusClick(e, row.id, row.status);
                            }}
                          />
                        )}
                        {row.status === 9 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Cancelled"
                            cancelled={+true}
                          />
                        )} */}
                      </TableCell>
                      <TableCell align="left">{row.method}</TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <IconButton aria-label="delete" size="large">
                            <ArchiveIcon />
                          </IconButton>
                          <IconButton aria-label="details" size="large">
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function InvoiceList() {
  return (
    <React.Fragment>
      <Helmet title="Invoices" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Invoices
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Invoices</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Invoice
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InvoiceList;
