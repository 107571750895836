import { createContext, useRef, useEffect, useState } from "react";
import { useScript } from "../hooks/useScript";
import { useConfigureAxios } from "../hooks/useConfigureAxios";
import { loadState } from "../redux/localStorage";

import { lendicaDefaultCredentials, lendicaDefaultConfig } from "../config";
import axios from "axios";

const LendicaContext = createContext();

const client = loadState("client");

const LendicaProvider = ({ children }) => {
  const loadStatus = useScript(
    loadState("scriptUrl") ??
      "https://static-idev.golendica.com/test/lendica.js" //"https://static-idev.golendica.com/v2/lendica.js"
  );
  const triggerStatus = useScript(
    "https://static-idev.golendica.com/test/ibranch-trigger.js"
  );
  const lendica = useRef(null);
  const isConfigured = useConfigureAxios();
  const [checkoutRendered, setCheckoutRendered] = useState(false);

  const updateCheckoutRenderState = (state) => {
    setCheckoutRendered(state);
  };

  const clear = async () => {
    await lendica?.current?.destroy();
    window.location.reload();
  };

  const getSalesOrders = async () => {
    const response = await axios.get("/demo/invoices");
    return response.data;
  };

  const getPurchaseOrders = async () => {
    const response = await axios.get("/demo/bills");
    return response.data;
  };

  const resetCompany = async () => {
    const response = await axios.get("/demo/reset");
    return response.data;
  };

  const resetCompanyApproved = async () => {
    const response = await axios.get("/demo/reset_w_approval");
    return response.data;
  };

  useEffect(() => {
    if (loadStatus === "ready") {
      if (!lendica.current) {
        lendica.current = window.lendica;
      }
      setTimeout(() => {
        lendica.current
          .init(
            client?.lendicaCredentials ?? lendicaDefaultCredentials,
            client?.lendicaConfig ?? lendicaDefaultConfig
          )
          .then(() => {
            lendica.current.ibranch.render();
          });
      }, 0);
    }
  }, [loadStatus]);

  return (
    <LendicaContext.Provider
      value={{
        lendica,
        clear,
        checkoutRendered,
        updateCheckoutRenderState,
        isConfigured,
        getSalesOrders,
        getPurchaseOrders,
        resetCompany,
        resetCompanyApproved,
      }}
    >
      {children}
    </LendicaContext.Provider>
  );
};

export { LendicaContext, LendicaProvider };
