import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
} from "react-feather";

const pagesSection = [
  {
    href: "/dashboard/default",
    icon: Sliders,
    title: "DrawDown",
    // children: [
    //   {
    //     href: "/dashboard/default",
    //     title: "",
    //   },
    // {
    //   href: "/dashboard/analytics",
    //   title: "Ad-tech",
    // },
    // {
    //   href: "/dashboard/saas",
    //   title: "Point of Sale",
    // },
    // ],
  },
  {
    href: "/invoices",
    icon: ShoppingCart,
    title: "FundNow/PayLater",
    children: [
      {
        href: "/fundnow",
        title: "Invoices",
      },
      {
        href: "/paylater",
        title: "Bills",
      },
      {
        href: "/invoices/detail",
        title: "Checkout",
      },
    ],
  },
  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Demo Video",
  //   children: [
  //     {
  //       href: "/components/alerts",
  //       title: "Youtube Channel",
  //     },
  //   ],
  // },
];

const elementsSection = [
  {
    href: "/forms",
    icon: Grid,
    title: "Settings",
    children: [
      {
        href: "/forms/formik",
        title: "Inputs",
      },
    ],
  },
];

const docsSection = [
  {
    href: "/documentation", //"https://docs.golendica.com",
    icon: BookOpen,
    title: "Documentation v1.9",
  },
];

const navItems = [
  {
    title: "Lendica Integration",
    pages: pagesSection,
  },
  // {
  //   title: "Config",
  //   pages: elementsSection,
  // },
  {
    title: "More",
    pages: docsSection,
  },
];

export default navItems;
