import axios from "axios";
import { useEffect, useState, useMemo } from "react";

export const useConfigureAxios = () => {
  const [isConfigured, setIsConfigured] = useState(false);
  const backendURL = useMemo(() => process.env.REACT_APP_BACKEND_URL, []);
  useEffect(() => {
    axios.defaults.baseURL = `${backendURL}`;
    axios.interceptors.response.use(
      (response) => response,
      (error) =>
        Promise.reject(
          (error.response && error.response.data) || "Something went wrong"
        )
    );
    setIsConfigured(true);
  }, [backendURL]);
  return isConfigured;
};
