import axios from "axios";

export const postBill = async (data) => {
  const redirectUrl = (
    await axios.post("/submit/paylater/bill", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${process.env.REACT_APP_DEMO_API_KEY}`,
      },
    })
  ).data;
  return redirectUrl;
};
